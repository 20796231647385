<template>
	<div class="content">
		<div class="header">
			<div class="title" v-html="project.name"></div>
			<slot name="header_menu_right"></slot>
		</div>
		<template v-if="loaded">
			<div class="sub-header-info">
				<div class="data" v-if="project.responsible">
					<div class="label">{{ $t("general.responsible") }}:</div>
					<div class="data-info">
						<!-- <img :src="project.responsible.avatar" :alt="project.responsible.name"> -->
						<span class="text">{{ project.responsible.name }}</span>
					</div>
				</div>
				<div class="data progress-editor">
					<div class="label">{{ $t("general.progress") }}:</div>
					<div class="progress-bar">
						<input
							v-model="project.progress"
							type="range"
							min="0"
							max="100"
							class="slider-progress"
							:style="`background-size: ${project.progress}% 100%;`"
							@change="updateProgress"
							v-if="project.rights.editor"
						/>
						<div class="slider-progress" :style="`background-size: ${project.progress}% 100%;`" v-else></div>
						<div class="text-percent">{{ project.progress }}%</div>
					</div>
				</div>
			</div>
		</template>

		<template v-if="loaded">
			<div class="body">
				<div class="container-form">
					<div class="table-list">
						<template v-for="(task, index) in project.tasks">
							<RowTask :task="task" :index="index" :extraInfo="['update', 'project', 'organigrama', 'date', 'priority', 'user']" fromStep="project" v-if="checkRight(task)" />
							<ReadTask :task="task" :extraInfo="['project', 'organigrama', 'date', 'priority', 'user']" fromStep="project" v-else />

							<template v-for="taskLog in task.task_logs">
								<RowTask
									:task="taskLog"
									:index="index"
									:extraInfo="['update', 'space-log', 'project', 'organigrama', 'date', 'priority', 'user']"
									fromStep="project"
									v-if="checkRight(taskLog)"
								/>
								<ReadTask :task="taskLog" :extraInfo="['space-log', 'project', 'organigrama', 'date', 'priority', 'user']" fromStep="project" v-else />
							</template>
						</template>

						<div class="row-item add-item" v-if="$parent.can_lead || project.rights.editor">
							<div class="column-icon">
								<icon-plus />
							</div>
							<div class="column-input">
								<input type="text" :placeholder="$t('dashboard.placeholder_add_task')" v-model="new_task.name" @keyup.enter="storeTask" />
							</div>
							<div class="column-input-date">
								<div class="deadline-calendar top">
									<InputCalendarPicker
										:data="new_task.date"
										@updateDate="
											(date) => {
												new_task.date = date.value;
											}
										"
									/>
								</div>
							</div>
							<div class="column-input-flag">
								<InputPriorityCheck
									:data="new_task.priority"
									@updatePriority="
										(priority) => {
											new_task.priority = priority.value;
										}
									"
								/>
							</div>
							<div class="column-input-avatar">
								<InputUserSelect
									:data="new_task.responsibleId ? new_task.responsibleId : ''"
									:optionsUsers="optionsUsers"
									@updateUser="
										(responsible) => {
											new_task.responsibleId = responsible.value;
										}
									"
								/>
							</div>
							<div class="column-button">
								<button class="btn-tbf blue center" @click="storeTask">
									<div class="text">{{ $t("general.add") }}</div>
								</button>
							</div>

							<div class="row-info-action" v-if="new_task.name">
								<div class="text">{{ $t("meeting_session.remainder_add_task") }}</div>
							</div>
						</div>
					</div>
					<slot name="form_submit"></slot>
				</div>
			</div>
		</template>
		<LoaderStepMeeting v-else />
	</div>
</template>

<script>
import IconTask from "@/components/Icons/Task";
import IconCheck from "@/components/Icons/Check";
import IconRepeat from "@/components/Icons/Repeat";
import IconDots from "@/components/Icons/EditDots";
import IconPlus from "@/components/Icons/Plus";

import InputCalendarPicker from "@/components/ComponentsTask/CalendarPicker";
import InputPriorityCheck from "@/components/ComponentsTask/PriorityCheck";
import InputUserSelect from "@/components/ComponentsTask/UserSelect";
import InputName from "@/components/ComponentsTask/InputName";
import RowTask from "@/components/ComponentsTask/RowTask";
import ReadTask from "@/components/ComponentsTask/ReadTask";

import LoaderStepMeeting from "@/components/PagesLoaders/MeetingStep";

import { debounce } from "debounce";

export default {
	name: "ProjectStep",
	components: {
		IconTask,
		IconCheck,
		IconRepeat,
		IconDots,
		InputCalendarPicker,
		InputPriorityCheck,
		InputUserSelect,
		InputName,
		IconPlus,
		RowTask,
		LoaderStepMeeting,
		ReadTask,
	},
	props: {
		data: Object | Boolean,
		optionsUsers: Array,
	},
	data() {
		return {
			loaded: false,
			project: {},
			new_task: {
				name: "",
				priority: "",
				date: "",
				responsibleId: "",
			},
		};
	},
	watch: {
		data(newValue) {
			this.loaded = false;
			this.getProject();
		},
	},
	created() {
		this.handleDebounceUpdateTask = debounce((task) => {
			this.updateTask(false, task);
		}, 1000);
	},
	beforeDestroy() {
		this.$root.$off("updateTaskMeeting");
	},
	async mounted() {
		await this.getProject();

		this.$root.$on("updateTaskMeeting", (taskData) => {
			this.addTaskFromWebsocket(taskData);
		});

		this.$root.$on("updateProjectProgress", (projectData) => {
			this.project.progress = projectData.progress;
		});
	},
	methods: {
		async getProject() {
			await axios
				.get(`/public/${this.$route.params.uuid}/projects/${this.data.slug}/tasks`)
				.then(({ data }) => {
					this.project = data.data;
				})
				.finally(() => {
					this.loaded = true;
				});
		},
		addTaskFromWebsocket(taskData) {
			switch (taskData.action) {
				case "store":
					this.project.tasks.push({ ...taskData, task_id: taskData.id });
					break;
				case "update":
					// Function to recursively search for the task in parent tasks and their task_logs
					function findAndUpdateTask(arr) {
						for (const el of arr) {
							var checkType = false;
							if (taskData.frequency) {
								checkType = true;
							}

							if (
								el.task_id === taskData.id &&
								(el.date === taskData.date || (taskData.hasOwnProperty("old_task_log_date") && el.date === taskData.old_task_log_date)) &&
								(!checkType || (checkType && el.type === taskData.type))
							) {
								Object.assign(el, {
									id: taskData.id,
									task_id: taskData.id,
									name: taskData.name,
									date: taskData.date,
									deadline: taskData.deadline,
									done: taskData.done,
									frequency: taskData.frequency,
									priority: taskData.priority,
									responsible: taskData.responsible,
								});

								if (taskData.type === "task" && taskData.frequency) {
									// Update all logs
									el.task_logs.forEach((log) => {
										Object.assign(log, {
											id: taskData.id,
											task_id: taskData.id,
											name: taskData.name,
											date: taskData.date,
											deadline: taskData.deadline,
											done: taskData.done,
											frequency: taskData.frequency,
											priority: taskData.priority,
											responsible: taskData.responsible,
										});
									});
								}
								return true; // Task found and updated
							}
							if (el.task_logs && findAndUpdateTask(el.task_logs)) {
								return true; // Task found and updated in task_logs
							}
						}
						return false; // Task not found
					}

					findAndUpdateTask(this.project.tasks);
					break;
				case "destroy":
					// Found task and delete it
					const taskIndex = this.project.tasks.findIndex((el) => el.task_id === taskData.id && el.date === taskData.date);
					if (taskIndex !== -1) {
						this.project.tasks.splice(taskIndex, 1);
					} else {
						if (taskData.type === "task") {
							const taskParentIndex = this.project.tasks.findIndex((el) => el.task_id === taskData.id);
							if (taskParentIndex !== -1) {
								this.project.tasks.splice(taskParentIndex, 1);
							}
						}
					}
					break;
			}
		},
		changeStepFunction(direction, stepNo = false) {
			if (direction) {
				this.$emit(direction);
			} else if (stepNo) {
				this.$emit("goToStep", stepNo);
			}
		},
		changeStepOrRedirect(changeStep, withRedirect, userSlug) {
			if (changeStep) {
				this.$nextTick(() => {
					if (!changeStep.step) {
						this.$emit(changeStep.direction);
					} else {
						this.$emit("goToStep", changeStep.step);
					}
				});
			}

			if (withRedirect) {
				if (this.$route.query.department_id) {
					this.$router.push({ name: "company" });
				} else {
					this.$router.push({ name: "user-show", params: { slug: userSlug } });
				}
			}
		},
		storeTask() {
			if (this.new_task.name != "") {
				var formData = {
					name: this.new_task.name,
					project_id: this.project.id,
					user_id: this.new_task.responsibleId ? this.new_task.responsibleId : this.$auth.user().id,
					from_meeting_id: this.$parent.meetingLog.id,
					from_meeting_step: "project",
				};

				if (this.new_task.priority != "") {
					formData.priority = this.new_task.priority;
				}

				if (this.new_task.date != "") {
					formData.date = this.new_task.date;
					formData.deadline = this.new_task.date;
					formData.start_date = this.new_task.date;
				} else {
					formData.date = moment().format("YYYY-MM-DD");
					formData.deadline = moment().format("YYYY-MM-DD");
					formData.start_date = moment().format("YYYY-MM-DD");
				}

				axios.post(`/tasks/store`, formData).then(({ data }) => {
					// this.getProject();
					this.project.tasks.push({
						...formData,
						id: data.data.task_log_id ? data.data.task_log_id : data.data.id,
						responsible: this.optionsUsers.find((el) => el.id == formData.user_id),
						done: 0,
						type: data.data.task_log_id ? "task_log" : "task",
						task_id: data.data.id,
					});

					this.new_task = { name: "", priority: "", date: "", responsibleId: "" };
				});
			}
		},
		debounceUpdateTask(task) {
			this.handleDebounceUpdateTask(task);
		},
		updateTask(dataComponent, task) {
			var paramsQuery = {};

			var taskFormObj = {
				name: task.name ? task.name : "Unamed",
				project_id: this.project.id,
				user_id: task.responsibleId ? task.responsibleId : "",
				priority: task.priority ? task.priority : "",
				date: task.date,
				from_meeting_id: this.$parent.meetingLog.id,
				from_meeting_step: "project",
			};

			if (task.frequency != null || task.type === "task" || (task.type === "task_log" && task.frequency === null)) {
				paramsQuery.all = 1;
			} else {
				paramsQuery.current = 1;
			}

			// // if task is log send Id
			// if(task.type == "task_log" ) {
			//     paramsQuery.task_log_id = task.id;
			// }

			if (dataComponent) {
				switch (dataComponent.type) {
					case "responsible":
						taskFormObj.user_id = dataComponent.value;
						break;
					case "priority":
						taskFormObj.priority = dataComponent.value;
						break;
					case "date":
						task.date = dataComponent.value;
						break;
				}
			}

			if (task.date) {
				taskFormObj.deadline = task.date;
				if (!task.frequency) {
					taskFormObj.start_date = task.date;
					taskFormObj.date = task.date;
				}
			}

			var parentTaskId = task.type == "task_log" ? task.task_id : task.id;

			axios.post(`/tasks/${parentTaskId}/update`, taskFormObj, { params: paramsQuery }).then(() => {});
		},
		updateProgress() {
			var formData = {
				progress: this.project.progress,
				from_meeting_id: this.$parent.meetingLog.id,
				from_meeting_step: "project",
			};

			axios.patch(`/projects/${this.project.slug}`, formData);
		},
		checkRight(task) {
			if (this.$parent.can_lead) {
				return true;
			}

			if (task.responsible && this.$auth.check() ? task.responsible.id == this.$auth.user().id : false) {
				return true;
			}

			if (this.project.rights.editor) {
				return true;
			}

			return false;
		},
	},
};
</script>
