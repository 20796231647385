<template>
	<div class="content">
		<template v-if="loaded">
			<div class="header">
				<div class="title-row">
					<div class="icon-repeat" v-if="task.type === 'task' && task.frequency != null"><icon-repeat /></div>

					<div
						class="checkbox-tbf-special"
						:class="{ active: task.done, 'non-clickable': !canEdit }"
						@click="canEdit ? toggleCheck(task) : ''"
						v-else
					>
						<icon-check />
					</div>
					<div class="title" v-html="task.name"></div>
				</div>

				<slot name="header_menu_right"></slot>
			</div>

			<div class="sub-header-info">
				<div class="data avatar" v-if="task.user">
					<img :src="task.user.avatar" :alt="task.user.name" />
					<span class="text">{{ task.user.name }}</span>
				</div>

				<div :class="`data priority ${task.priority}`">
					<div class="icon-priority">
						<icon-flag />
					</div>
				</div>

				<div class="data task-tags" v-if="task.tags && task.tags.length">
					<div
						class="tag"
						v-for="tag in task.tags"
						:style="`--color: ${tag.color_code}; --background-color: ${tag.color_code}30`"
					>
						<div class="text" :style="`--color: ${tag.color_code}`">
							{{ tag.name }}
						</div>
					</div>
				</div>

				<div
					class="data ml-auto"
					v-if="$auth.check() && ($parent.can_lead || (task.user ? task.user.id == $auth.user().id : false)) && !task.frequency"
				>
					<div class="label">{{ $t("general.actions") }}</div>
					<div class="data-info">
						<button class="action-link" @click="openTask">{{ $t("general.edit") }}</button>
					</div>
				</div>
			</div>

			<div class="sub-header-info lvl2">
				<div class="data project-info" v-if="task.project">
					<div class="label">{{ $t("meeting_session.project") }}</div>

					<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{ show: 200, hide: 0 }">
						<div class="data-info">
							<span class="text">{{ task.project.name }}</span>
						</div>

						<template slot="popover">
							<span class="simple-text" v-html="task.project.name"></span>
						</template>
					</v-popover>
				</div>

				<div class="data" v-if="task.estimation">
					<div class="label">{{ $t("meeting_session.estimation") }}</div>
					<div class="data-info">
						<icon-timer class="icon" />
						<span class="text">{{ $tc("meeting_session.hours", task.estimation) }}</span>
					</div>
				</div>
				<div class="data" v-if="task.duration">
					<div class="label">{{ $t("meeting_session.duration") }}</div>
					<div class="data-info">
						<icon-hourglass class="icon" />
						<span class="text">{{ convertSecondsToHMS(task.duration) }}</span>
					</div>
				</div>

				<div class="data">
					<div class="label">{{ $t("meeting_session.frequency") }}</div>

					<div class="data-info" v-if="task.frequency == null">
						<span class="text">{{ $t("meeting_session.frequencies.one_time") }}</span>
					</div>
					<div class="data-info" v-else-if="task.frequency == 'daily'">
						<span class="text">{{ $t(`responsibility.frequencies.${task.frequency}`) }}</span>
					</div>

					<v-popover
						offset="5"
						trigger="hover"
						placement="bottom"
						popoverBaseClass="popover-tbf"
						:delay="{ show: 200, hide: 0 }"
						v-else-if="task.frequency == 'weekly'"
					>
						<div class="data-info">
							<span class="text">{{ $t(`responsibility.frequencies.${task.frequency}`) }}</span>
						</div>

						<template slot="popover">
							<span class="simple-text">{{
								task.measurement.length
									? task.measurement
											.map((a) => {
												return $t(`calendar.long_days.${a}`);
											})
											.join(", ")
									: ""
							}}</span>
						</template>
					</v-popover>

					<v-popover
						offset="5"
						trigger="hover"
						placement="bottom"
						popoverBaseClass="popover-tbf"
						:delay="{ show: 200, hide: 0 }"
						v-else-if="task.frequency == 'monthly'"
					>
						<div class="data-info">
							<span class="text">{{ $t(`responsibility.frequencies.${task.frequency}`) }}</span>
						</div>

						<template slot="popover">
							<span class="description">{{ task.measurement.length ? task.measurement.join(", ") : "" }}</span>
						</template>
					</v-popover>

					<v-popover
						offset="5"
						trigger="hover"
						placement="bottom"
						popoverBaseClass="popover-tbf"
						:delay="{ show: 200, hide: 0 }"
						v-else-if="task.frequency == 'monthly_dynamically'"
					>
						<div class="data-info">
							<span class="text">{{ $t(`responsibility.frequencies.${task.frequency}`) }}</span>
						</div>

						<template slot="popover">
							<span class="description">
								{{
									task.measurement_dynamically
										.map((a) => {
											return $t(`calendar.weeks.${a}`);
										})
										.join(", ")
										.replace(/, ([^,]*)$/, ` ${$t("general.and")} $1`)
								}}
								<br /><br />
								{{
									task.measurement
										.map((a) => {
											return $t(`calendar.number_days.${a}`);
										})
										.join(", ")
										.replace(/, ([^,]*)$/, ` ${$t("general.and")} $1`)
								}}
							</span>
						</template>
					</v-popover>

					<v-popover
						offset="5"
						trigger="hover"
						placement="bottom"
						popoverBaseClass="popover-tbf"
						:delay="{ show: 200, hide: 0 }"
						v-else-if="task.frequency == 'yearly'"
					>
						<div class="data-info">
							<span class="text">{{ $t(`responsibility.frequencies.${task.frequency}`) }}</span>
						</div>

						<template slot="popover">
							<span class="simple-text">
								{{
									task.measurement.length
										? task.measurement
												.map((a) => {
													return moment(a, "MM-DD").format("DD.MM");
												})
												.join(", ")
										: ""
								}}
							</span>
						</template>
					</v-popover>
				</div>

				<div class="data" v-if="task.deadline">
					<div class="label">{{ $t("show_user.deadline") }}</div>
					<div class="data-info">
						<span class="text">{{ moment(task.deadline).format("DD MMMM") }}</span>
					</div>
				</div>
			</div>

			<div class="body">
				<div class="container-form">
					<div class="description" v-html="task.description"></div>

					<div class="table-list" v-if="task.task_logs">
						<template v-for="(task, index) in task.task_logs">
							<RowTask
								:task="task"
								:index="index"
								:extraInfo="['update', 'organigrama', 'date', 'priority', 'user']"
								v-if="
									$auth.check() &&
										($parent.can_lead || (task.responsible ? task.responsible.id == $auth.user().id : false))
								"
							/>
							<ReadTask :task="task" :extraInfo="['organigrama', 'date', 'priority', 'user']" v-else />
						</template>
					</div>
					<slot name="form_submit"></slot>
				</div>
			</div>
		</template>
		<LoaderTask v-else />
	</div>
</template>

<script>
import IconTask from "@/components/Icons/Task";
import IconCheck from "@/components/Icons/Check";
import IconFlag from "@/components/Icons/Flag";
import IconTimer from "@/components/Icons/Timer";
import IconHourglass from "@/components/Icons/Hourglass";
import IconEdit from "@/components/Icons/Edit";
import RowTask from "@/components/ComponentsTask/RowTask";
import ReadTask from "@/components/ComponentsTask/ReadTask";
import IconRepeat from "@/components/Icons/Repeat";

import LoaderTask from "@/components/PagesLoaders/MeetingTask";

export default {
	components: {
		IconTask,
		IconCheck,
		IconFlag,
		IconTimer,
		IconHourglass,
		IconEdit,
		RowTask,
		ReadTask,
		LoaderTask,
		IconRepeat,
	},
	props: {
		optionsUsers: Array,
		data: Object | Boolean,
	},
	watch: {
		data(newValue) {
			this.loaded = false;
			this.getTaskData();
		},
	},
	data() {
		return {
			loaded: false,
			loadedFinish: false,
			typeSubject: "evaluation",
			guests: [],
			task: {},
			loaded: false,
		};
	},
	computed: {
		canEdit() {
			return this.$auth.check() && (this.$parent.can_lead || (this.task.user ? this.task.user.id == this.$auth.user().id : false));
		},
	},
	beforeDestroy() {
		this.$root.$off("updateTaskMeeting");
	},
	async mounted() {
		this.getTaskData();

		this.$root.$on("updateTaskMeeting", (taskData) => {
			this.updateTaskFromWS(taskData);
		});
	},
	methods: {
		getTaskData() {
			axios
				.get(`/tasks/${this.data.id}/edit`, {
					params: {
						date: this.data.deadline && !this.data.hasOwnProperty("frequency") ? this.data.deadline : "",
						from_meeting_session: true,
					},
				})
				.then(({ data }) => {
					this.task = data.data;
				})
				.finally(() => {
					this.loaded = true;
				});
		},
		updateTaskFromWS(taskData) {
			var checkType = false;
			if (this.task.hasOwnProperty("frequency") && this.task.frequency) {
				checkType = true;
			}

			if (
				this.task.parent_id === taskData.id &&
				(this.task.date === taskData.date ||
					(taskData.hasOwnProperty("old_task_log_date") && this.task.date === taskData.old_task_log_date)) &&
				(!checkType || (checkType && this.task.type === taskData.type))
			) {
				Object.assign(this.task, {
					id: taskData.id,
					// task_id: taskData.id,
					name: taskData.name,
					date: taskData.date,
					deadline: taskData.deadline,
					done: taskData.done,
					frequency: taskData.frequency,
					priority: taskData.priority,
					responsible: taskData.responsible,
				});
			} else if (this.task.task_logs && this.task.task_logs.length) {
				var taskLogFound = this.task.task_logs.find(
					(taskLog) =>
						taskLog.task_id === taskData.id &&
						(taskLog.date === taskData.date ||
							(taskData.hasOwnProperty("old_task_log_date") && taskLog.date === taskData.old_task_log_date))
				);

				if (taskLogFound) {
					Object.assign(taskLogFound, {
						id: taskData.id,
						// task_id: taskData.id,
						name: taskData.name,
						date: taskData.date,
						deadline: taskData.deadline,
						done: taskData.done,
						frequency: taskData.frequency,
						priority: taskData.priority,
						responsible: taskData.responsible,
					});
				}
			}
		},
		changeStepFunction(direction, stepNo = false) {
			if (direction) {
				this.$emit(direction);
			} else if (stepNo) {
				this.$emit("goToStep", stepNo);
			}
		},
		changeStepOrRedirect(changeStep, withRedirect, userSlug) {
			if (changeStep) {
				this.$nextTick(() => {
					if (!changeStep.step) {
						this.$emit(changeStep.direction);
					} else {
						this.$emit("goToStep", changeStep.step);
					}
				});
			}

			if (withRedirect) {
				if (this.$route.query.department_id) {
					this.$router.push({ name: "company" });
				} else {
					this.$router.push({ name: "user-show", params: { slug: userSlug } });
				}
			}
		},
		toggleCheck(task) {
			var newValueDone = task.done === 1 ? 0 : 1;
			task.done = newValueDone;
			var newDate = "";

			if (!task.date) {
				newDate = moment().format("YYYY-MM-DD");
			} else {
				newDate = moment(task.date).format("YYYY-MM-DD");
			}

			axios.post(`/tasks/${task.parent_id ? task.parent_id : task.id}/check`, {
				date: newDate,
				done: newValueDone,
				from_meeting_id: this.$parent.meetingLog.id,
				from_meeting_step: this.task.project ? "project" : "task",
			});
		},
		openTask() {
			this.$root.$emit(
				"open_modal_full_template",
				"add_task",
				{
					crud: "edit",
					taskEditId: this.task.parent_id,
					// type: this.task.type,
					date: this.task.hasOwnProperty("frequency") && this.task.frequency ? "" : this.task.date,
					from_meeting_id: this.$parent.meetingLog.id,
					from_meeting_step: this.task.project ? "project" : "task",
				},
				false
			);
		},
		convertSecondsToHMS(seconds) {
			const hours = Math.floor(seconds / 3600);
			const minutes = Math.floor((seconds % 3600) / 60);
			const secondsRemaining = seconds % 60;
			const formattedHours = hours.toString().padStart(2, "0");
			const formattedMinutes = minutes.toString().padStart(2, "0");
			const formattedSeconds = secondsRemaining.toString().padStart(2, "0");
			return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
		},
	},
};
</script>
