<template>
	<div class="background-page new-form-page meeting-form">
		<div class="form-wizard-container">
			<template v-if="loaded">
				<div class="sidebar">
					<div class="title-form">
						<h1>{{ meetingLog.name }}</h1>
					</div>
					<div class="menu-wizard">
						<div
							class="step-item"
							@click="changeStep(step)"
							:class="{
								active: step.order === activeStep,
								disabled: disabledSteps && !['review', 'final_recap'].includes(step.key) ? true : false,
							}"
							v-for="step in steps"
						>
							<template v-if="!step.deleted_at">
								<div class="circle"></div>
								<div class="text" v-if="step.from == 'default'">{{ $t(`meeting_session.${step.key}`) }}</div>
								<div class="text" v-else v-html="step.name"></div>
							</template>
						</div>
					</div>

					<div class="footer-info">
						<div class="copy-link" v-if="status != 'finished'">
							<p class="text">{{ $t("meeting_session.copy_meeting_link") }}</p>
							<div class="info">
								<input id="divToCopy" :value="meeting_public_link" readonly />
								<div @click="copyLink">
									<icon-clone class="icon" v-if="!copied" />
									<icon-check class="icon green" v-else />
								</div>
							</div>
						</div>
						<div class="meeting-link" v-if="meetingLog.link">
							<p class="text">{{ $t("meeting_session.access_meeting") }}</p>
							<p class="link">{{ meetingLog.link }}</p>
						</div>
						<div class="counter-duration">
							<p class="clock" v-if="status != 'finished'">{{ hours }}:{{ minutes }}:{{ seconds }}</p>
							<p class="clock" v-else>
								{{ $t("meeting_session.duration") }}:
								{{ `${String(durationHours).padStart(2, "0")}:${String(durationMinutes).padStart(2, "0")}` }}
							</p>
							<p class="text">
								{{
									$t("meeting_session.started_at", {
										date: moment(targetDate, "YYYY-MM-DD HH:mm").format("HH:mm, dddd D MMM"),
									})
								}}
							</p>
						</div>
					</div>
				</div>

				<component :is="activeComponent" :data="dataComponent[activeComponent]" :optionsUsers="optionsUsers">
					<template v-slot:header_menu_right v-if="loaded">
						<div class="actions">
							<template v-if="$auth.check() && status == 'finished'">
								<router-link :to="{ name: 'dashboard_user' }" class="btn-tbf blue btn-next-step" @click="goToDashboard">
									<div class="text">{{ $t("meeting_session.back_to_dashboard") }}</div>
								</router-link>
							</template>
							<template v-else-if="$auth.check() && captain.id != $auth.user().id && can_lead">
								<button class="btn-tbf blue btn-next-step" @click="changeCaptain">
									<div class="text">{{ $t("meeting_session.take_over") }}</div>
								</button>
							</template>

							<div class="btn-captain">
								<div class="text">
									<span class="captain">{{ $t("meeting_session.captain") }}</span>
									<span class="name">{{ captain.name }}</span>
								</div>
								<img :src="captain.avatar" v-if="captain.avatar" />
							</div>
						</div>
					</template>
					<template v-slot:form_submit v-if="loaded">
						<div class="form-submit">
							<div class="action-form">
								<template v-if="$auth.check() && captain.id == $auth.user().id">
									<button
										class="btn-tbf green btn-next-step"
										@click="endMeeting"
										v-if="activeComponent === 'final-recap'"
									>
										<div class="text">{{ $t("general.finish") }}</div>
									</button>
									<button class="btn-tbf blue btn-next-step" @click="nextStep" v-else>
										<div class="text">{{ $t("meeting_session.next_step") }}</div>
									</button>
								</template>
							</div>
						</div>
					</template>
				</component>

				<sidebar-topic v-if="activeComponent === 'subject' && can_lead" :currentTopic="meetingTopic" @updateTopic="generateSteps" />
				<sidebar-comments
					ref="sidebarComments"
					v-else-if="!['presence', 'subject', 'review', 'final-recap'].includes(activeComponent)"
					:step="dataComponent[activeComponent]"
					:comments="meetingLog.comments"
				/>
			</template>
		</div>
	</div>
</template>

<script>
import IconClose from "@/components/Icons/Close";
import IconClone from "@/components/Icons/Clone";
import IconCheck from "@/components/Icons/Check";
import IconArrow from "@/components/Icons/AngleRight";
import LoaderSidebar from "@/components/PagesLoaders/MeetingSidebar";

import SidebarTopic from "./SidebarTopic";
import SidebarComments from "./SidebarComments";

import Presence from "./Presence";
import Subject from "./Subject";
import Project from "./Project";
import Objective from "./Objective";
import KeyResult from "./KeyResult";
import PlanningTheDay from "./PlanningTheDay";
import LastMeeting from "./PlanningTheDay";
import Task from "./Task";
import Custom from "./CustomTopic";
import Review from "./ReviewMeeting";
import FinalRecap from "./FinalRecap";

export default {
	components: {
		IconClose,
		IconClone,
		IconCheck,
		IconArrow,
		Presence,
		Subject,
		SidebarTopic,
		Project,
		Objective,
		Task,
		KeyResult,
		LastMeeting,
		PlanningTheDay,
		LoaderSidebar,
		SidebarComments,
		Custom,
		Review,
		FinalRecap,
	},
	computed: {
		soft_objectives() {
			return this.$store.getters["applications/getApplication"]("Obiective");
		},
		soft_organigram() {
			return this.$store.getters["applications/getApplication"]("Organigrama");
		},
		loadedSofts() {
			return this.$store.state.applications.applications.length;
		},
	},
	data() {
		return {
			loaded: false,
			activeStep: 1,
			activeComponent: "presence",
			steps: [],
			meetingLog: {},
			meetingSessionId: "",
			targetDate: "",
			meetingTopic: [],
			captain: {},
			hours: 0,
			minutes: 0,
			seconds: 0,
			copied: false,
			dataComponent: {
				subject: [],
				project: {},
			},
			meeting_public_link: `${process.env.VUE_APP_ORGANIGRAM_FE_LINK}/meeting/${this.$route.params.uuid}`,
			masterInstanceId: "",
			optionsUsers: [],
			can_lead: false,
			socket: null,
			disabledSteps: false,
			intervalTimer: {},
			status: "",
			duration: "",
			durationHours: "",
			durationMinutes: "",
		};
	},
	async mounted() {
		if (this.$auth.check()) {
			await this.getOptionsUsers();
		}
		await this.getMeetingInfo();

		if (this.status != "finished") {
			this.intervalTimer = setInterval(() => {
				const currentDate = moment();
				const targetMoment = moment(this.targetDate, "YYYY-MM-DD HH:mm:ss");
				const timePassed = currentDate.diff(targetMoment);

				const duration = moment.duration(timePassed);
				let daysOnHours = duration.days() * 24;
				this.hours = (duration.hours() + daysOnHours).toString().padStart(2, "0");
				this.minutes = duration
					.minutes()
					.toString()
					.padStart(2, "0");
				this.seconds = duration
					.seconds()
					.toString()
					.padStart(2, "0");
			}, 1000);
		} else {
			this.durationHours = Math.floor(this.duration / 60);
			this.durationMinutes = this.duration % 60;
		}

		await this.initWebSocket();

		this.$root.$on("send_ws_message", (data) => {
			this.socket.send(JSON.stringify(data));
		});
	},
	beforeDestroy() {
		this.closeWebSocket();
		this.$root.$off("send_ws_message");
	},
	methods: {
		initWebSocket() {
			this.socket = new WebSocket(process.env.VUE_APP_WSS_LINK_MEETING);

			this.socket.onopen = (event) => {
				// check in to websocket
				this.socket.send(
					JSON.stringify({
						entity_id: this.meetingLog.id,
						user_id: this.$auth.check() ? this.$auth.user().id : this.$route.query.token_link,
						type: "meeting",
					})
				);
			};

			// when receiving a message
			this.socket.onmessage = ({ data }) => {
				// console.log(data);

				try {
					const parsedData = JSON.parse(data);
					this.checkMessageLocation(parsedData.custom_data);
				} catch (error) {
					console.error("Error parsing JSON data from WebSocket:", error);
					// Handle the error appropriately (e.g., show an error message to the user).
				}
			};

			this.socket.onerror = (error) => {
				console.log(`Websocket error`);
			};

			this.socket.onclose = (event) => {
				if (event.wasClean) {
					console.log(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
				} else {
					// e.g. server process killed or network down
					// event.code is usually 1006 in this case
					console.log("[close] Connection died");

					setTimeout(() => {
						this.initWebSocketProject();
					}, 600000);
				}
			};
		},
		closeWebSocket() {
			if (this.socket && this.socket.readyState === WebSocket.OPEN) {
				this.socket.close();
			}
		},
		checkMessageLocation(message) {
			switch (message.type) {
				case "project_task":
					this.$root.$emit("updateTaskMeeting", message.data);
					break;
				case "task":
					this.$root.$emit("updateTaskMeeting", message.data);
					break;
				case "update_presence":
					this.$root.$emit("updatePresenceMeeting", message.data);
					break;
				case "manage_invites":
					this.$root.$emit("updateMeetingInvites", message.data);
					break;
				case "change_captain":
					this.$root.$emit("changeMeetingCaptain", message.data);
					this.captain = message.data;
					break;
				case "topic":
					this.$root.$emit("updateSidebarTopics", message.data);
					this.meetingTopic = message.data;
					this.dataComponent["subject"] = this.meetingTopic;
					this.generateSteps();
					break;
				case "kr_task":
					this.$root.$emit("updateTaskKeyResultMeeting", message.data);
					break;
				case "kr_update":
					this.$root.$emit("updateKeyResultMeeting", message.data);
					break;
				case "promise":
					this.$root.$emit("updatePromiseMeeting", message.data);
					break;
				case "comment":
					this.updateComments(message.data);
					this.$root.$emit("updateCommentMeeting", message.data);
					break;
				case "project_progress":
					this.$root.$emit("updateProjectProgress", message.data);
					break;
				case "status":
					this.status = "finished";
					this.disabledSteps = true;
					var step = this.steps[this.steps.length - 1];
					this.goToReview(step);
					break;
			}
		},
		async getOptionsUsers() {
			await axios.get(`instances/${this.$auth.user().instance.id}/filter`, { params: { users: true } }).then(({ data }) => {
				this.optionsUsers = data.data.users;
			});
		},
		async getMeetingInfo() {
			await axios
				.get(`/public/meeting-session/${this.$route.params.uuid}/show`)
				.then(({ data }) => {
					this.meetingLog = data.data.meeting;
					this.meetingSessionId = data.data.id;
					this.targetDate = data.data.created_at;
					this.meetingTopic = data.data.topic;
					this.captain = data.data.captain;
					this.masterInstanceId = data.data.master_instance_id;
					this.can_lead = data.data.can_lead;
					this.status = data.data.status;
					this.duration = data.data.duration;

					this.dataComponent["subject"] = this.meetingTopic;

					this.generateSteps();
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 403) {
							this.$router.push({ name: "forbbiden" });
						} else if (error.response.status == 404) {
							this.$router.push({ name: "not-found" });
						} else if (error.response.status == 500) {
							alert(this.$t("error.500"));
						}
					}
				});
		},
		changeStep(step) {
			if (this.disabledSteps) {
				if (["review", "final_recap"].includes(step.key)) {
					this.activeStep = step.order;
					var itemTypeStrReplace = step.key.replace(/_/g, "-");
					this.activeComponent = itemTypeStrReplace;

					if (step.from === "topic" || ["last_meeting", "planning_the_day"].includes(step.key)) {
						this.dataComponent[itemTypeStrReplace] = step;
					}
				}
			} else {
				this.activeStep = step.order;
				var itemTypeStrReplace = step.key.replace(/_/g, "-");
				this.activeComponent = itemTypeStrReplace;

				if (step.from === "topic" || ["last_meeting", "planning_the_day"].includes(step.key)) {
					this.dataComponent[itemTypeStrReplace] = step;
				}
			}
		},
		nextStep() {
			// sort steps by order and then find the next step
			var stepFound = this.steps.sort((a, b) => a.order - b.order).find((step) => step.order > this.activeStep);
			this.changeStep(stepFound);
		},
		copyLink() {
			var copyText = document.getElementById("divToCopy");
			copyText.select();
			copyText.setSelectionRange(0, 99999);
			document.execCommand("copy");

			this.copied = true;
			setTimeout(() => {
				this.copied = false;
			}, 3000);
		},
		generateSteps() {
			// Reset steps
			this.steps = [];
			// Set default steps
			var orderStepNo = 1;
			this.steps.push({ key: "presence", order: orderStepNo++, from: "default" });

			if (!["daily_tasks", "evaluation"].includes(this.meetingLog.topic_type)) {
				this.steps.push({ key: "subject", order: orderStepNo++, from: "default" });
			}

			if (this.meetingLog.last_meeting_date) {
				this.steps.push({ key: "last_meeting", order: orderStepNo++, from: "default" });
			}
			// Set topic steps
			this.meetingTopic.map((el) => {
				this.steps.push({ ...el, key: el.type, order: orderStepNo++, from: "topic" });
			});

			// Set default steps

			if (!["task", "objective", "key_result", "custom_topic", "evaluation"].includes(this.meetingLog.topic_type)) {
				this.steps.push({ key: "planning_the_day", order: orderStepNo++, from: "default" });
			}

			this.steps.push({ key: "review", order: orderStepNo++, from: "default" });

			this.steps.push({ key: "final_recap", order: orderStepNo++, from: "default" });

			if (this.status == "finished") {
				this.disabledSteps = true;
				var step = this.steps[this.steps.length - 1];
				this.goToReview(step);
			}

			this.loaded = true;
		},
		endMeeting() {
			axios.patch(`/meeting-session/${this.meetingSessionId}/update`, { status: "finished" }).then(() => {
				this.$router.push({ name: "dashboard" });
			});
		},
		changeCaptain() {
			axios
				.post(`/meeting-session/${this.meetingLog.id}/change-captain`)
				.then(() => {
					this.captain.id = this.$auth.user().id;
					this.captain.avatar = this.$auth.user().avatar;
					this.captain.name = this.$auth.user().first_name + " " + this.$auth.user().last_name;
				})
				.finally(() => {
					this.$root.$emit("changeMeetingCaptain", this.captain);
				});
		},
		updateComments(dataAction) {
			switch (dataAction.action) {
				case "store":
					this.meetingLog.comments.push({ ...dataAction });
					break;
			}
		},
		goToReview(step) {
			this.activeStep = step.order;
			var itemTypeStrReplace = step.key.replace(/_/g, "-");
			this.activeComponent = itemTypeStrReplace;
		},
	},
};
</script>
